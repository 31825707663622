import { type FunctionComponent } from 'react';

import { type AdSizes } from '@/types/AdSizes';

import { graphql } from '@/lib/gql';
import parseAdPackageConfig from '@/utils/ads/parseAdPackageConfig';
import Ad from '@/components/Ad';

import { type FluidAdModuleProps } from './FluidAdModule.props';

export const FluidAdModuleFragment = graphql(/* GraphQL */ `
  fragment FluidAdModule on CurationModules {
    options {
      adSizes
      adTargeting
    }
  }
`);
const sizes: AdSizes = {
  desktop: [],
  laptop: [],
  mobile: ['fluid'],
  tablet: [],
};

const FluidAdModule: FunctionComponent<FluidAdModuleProps> = ({
  options,
  pageTargeting,
}) => {
  const { adTargeting } = options || {};

  const { targeting } = parseAdPackageConfig({
    adTargeting,
  });

  return (
    <Ad
      className="flex min-h-[250px] items-center justify-center rounded p-4 sm:hidden"
      pageTargeting={pageTargeting}
      sizes={sizes}
      targeting={targeting}
    />
  );
};

export default FluidAdModule;
