import { type FunctionComponent } from 'react';
import { Routes } from '@/constants';
import { CarMatchBannerModuleStrings as strings } from '@/translations';

import { DataId } from '@/utils/nitrous/constants';

import CarmatchBanner from '@/assets/logos/carmatch-banner-rightrail.svg';

const CarMatchBannerModule: FunctionComponent = () => {
  return (
    <a
      className="relative block"
      data-id={DataId.CarMatchBannerModule}
      data-parent={DataId.Banner}
      href={Routes.CarMatch}
    >
      <CarmatchBanner className="h-[350px]" title={strings.Title} />
    </a>
  );
};

export default CarMatchBannerModule;
