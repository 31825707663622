'use client';

import { useCallback, useState, type FunctionComponent } from 'react';
import cx from 'clsx';

import { type AdSizes } from '@/types/AdSizes';

import Ad from '../Ad';
import { type WallpaperAdProps } from './WallpaperAd.props';

const wallpaperAdSizes: AdSizes = {
  desktop: ['fluid'],
  laptop: ['fluid'],
  mobile: [],
  tablet: ['fluid'],
};

const wallpaperAdTargeting = { fluid: 'wallpaper' };

// Clip bottom of Wallpaper iframe to hide white line
const wallpaperAdClip = 'wallpaper-ad-clip';
const styleString = `
.${wallpaperAdClip} iframe:first-of-type {
  clip-path: inset(0 0 4px 0);
}
`;

const WallpaperAd: FunctionComponent<WallpaperAdProps> = ({
  children,
  pageTargeting,
}) => {
  const [showWallpaper, setShowWallpaper] = useState(false);

  const handleAdRendered = useCallback((isEmpty: boolean) => {
    if (!isEmpty) {
      setShowWallpaper(true);
    }
  }, []);

  return (
    <>
      <div
        className={cx(`fixed inset-0 -z-10`, {
          'bg-neutral-1': showWallpaper,
          'bg-neutral-2': !showWallpaper,
        })}
      />
      {showWallpaper && <style>{styleString}</style>}
      <Ad
        additionalAdPath="wallpaper"
        className={cx(
          `${wallpaperAdClip} fixed inset-0 mx-auto h-screen w-full max-w-[120rem]`,
          {
            hidden: !showWallpaper,
          },
        )}
        onAdRendered={handleAdRendered}
        pageTargeting={pageTargeting}
        sizes={wallpaperAdSizes}
        targeting={wallpaperAdTargeting}
      />
      <main
        className={cx(`dark mx-auto bg-neutral-2 main-container`, {
          'py-4 md:py-6': !showWallpaper,
          'relative mb-12 rounded-lg py-6 md:mx-6 md:mt-36 lg:mx-[12.25rem] lg:mt-40 xl:mt-[14.5rem] 2xl:mx-auto':
            showWallpaper,
        })}
      >
        {children}
      </main>
    </>
  );
};

export default WallpaperAd;
